body {
    background-color: #f5f7fa;
}

.logo-box{
    justify-content: left;
}

a {
    color: white;
}

.logo {
    height: 35px;
    margin: 7px;
}

.logo-type {
    margin-top: 13px;
}

.back-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5em;
    color: #f5f5f5;
}


@media only screen and (min-width: 992px) {
    .form {
        margin-top: 150px;
    }

    .register .form {
        margin-top: 80px;
    }
}

.row {
    margin: 0;
}

.register-right-section {
    background-color: #1a237e;
    display: block;
    position: relative;
    min-height: 100vh !important;
    border-radius: 50px 0 0 50px;
}

.register-right-section::after {
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
}

.register-right-section::after {
    height: 100%;
    background-image: url('../../../../public/images/section-3.jpg');
    border-radius: 50px 0 0 50px;
}

.register-left-section {
    padding-bottom: 40px !important;
    overflow-y: auto;
    height: 100%;
}

.error {
    display: block;
    border-right: 5px solid red;
    background-color: #fafafa;
    color: #212121;
    font-size: 1.2em;
    box-shadow: 0 0 15px #e0e0e0;
    padding: 20px;
}

.success {
    display: block;
    border-right: 5px solid green;
    background-color: #fafafa;
    color: #212121;
    font-size: 1.2em;
    box-shadow: 0 0 15px #e0e0e0;
    padding: 20px;
}

.right-section-content {
    padding: 30px;
    z-index: 2;
    position: relative;
    color: white;
}

.right-section-content h1{
    font-size: 3.5em;
}

.left-section-text {
    display: block;
    margin-top: 220px;
}

.left-section-text h4 {
    line-height: 1.5;
}

.left-section-text p{
    font-size: 1.5em;
}