.ai-modal .modal-dialog {
    margin-left: 80px;
    margin-top: 100px;
}

.ai-modal .msg {
    padding: 10px;
    width: fit-content;
}

.msg.msg-user {
    background: -webkit-linear-gradient(45deg, #666, #999);
    color: white;
    border-radius: 15px 0 15px 15px;
    align-self: flex-end;
}

.msg.msg-assistant {
    background: -webkit-linear-gradient(45deg, #5fc9f8, #5856d6);
    color: black;
    border-radius: 0 15px 15px 15px;
    align-self: flex-start;
}

.ai-modal .modal-body {
    min-height: 200px;
}

.ai-modal.loading .modal-content {
    border: 3px solid #0000;
    border-radius: 12px;
    background: linear-gradient(#131219, #131219) padding-box, linear-gradient(var(--angle),
            #070707,
            #687aff) border-box;
    animation: 2s rotate linear infinite;
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}