/* Globals */
body {
  font-family: "Open Sans";
}
p {
  color: #bebebe;
  line-height: 1.8;
}

h2 {
  font-size: 2.6rem;
  font-weight: normal;
}

ul {
  padding: 0;
  margin: 20px 0;
}

li {
  list-style: none;
}

li a {
  color: #bebebe;
  padding: 5px 0;
  display: block;
}

.landing-nav{
  background-color: #181920;
  border-radius: 4px;
}

.cta-btn {
  background: linear-gradient(45deg, #4458dc, #3ea9fe);
  padding: 10px 45px;
}

.hero {
  position: relative;
  background-color: #171717;
}

.hero-bc {
  position: absolute;
  width: 250px;
  height: 250px;
  left: -340px;
  top: 30px;

  background: linear-gradient(
    270deg,
    rgba(97, 106, 220, 0.8) -2.84%,
    rgba(107, 193, 245, 0.8) 100%
  );
  filter: blur(250px);
  transform: rotate(-90deg);
}

.hero-notes-bc {
  position: absolute;
  width: 250px;
  height: 250px;
  right: 0px;
  top: 30px;

  background: linear-gradient(
    270deg,
    rgba(97, 106, 220, 0.6) -2.84%,
    rgba(107, 193, 245, 0.6) 100%
  );
  filter: blur(400px);
  transform: rotate(-90deg);
}

.hero:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.navbar-brand {
  font-weight: bolder;
  color: white;
  font-size: 1.7rem;
}

.navbar .cta .nav-link {
  color: #fff;
  margin-left: 10px;
}

.navbar .cta .nav-link button{
padding: 5px 20px;
}

.navbar .navigation{
  flex: 1;
  justify-content: center;
  color: white;
}

.navbar-brand:hover {
  color: white;
}

.navbar .navigation .nav-link {
  margin: 5px 15px;
  color: #f5f5f5;
}
.navbar .navigation .nav-link.active {
  color: white;
  font-weight: bold;
}

.navbar-expand .me-auto {
  margin: auto;
}

.navbar .navbar-toggler {
  border-color: white;
  background-color: white;
  color: black;
}

.navbar .navbar-toggler span {
  color: white;
}

.btnsign {
  background: #1c1c1c;
  color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #5fc9f8;
}

.hero-content {
  position: relative;
  padding: 8vw 2em;
}

.hero-buttons a {
  margin: 1em 1em 0 0;
  font-weight: bold;
  flex: 1;
}

.hero-buttons a i {
  margin-left: 10px;
}

.pri-btn {
  background: linear-gradient(0deg, #616adc, #6bc1f5);
  border-color: #5fc9f8;
  border-width: 0;
  color: white;
  transition: background 0.5s;
  font-weight: normal;
  border-radius: 6px;
  font-size: 16px;
}

.pri-btn:hover {
color: white;
}

.hero-buttons a.sub-btn:hover {
  background: white;
  color: black;
  transition: background 0.2s, color 0.2s;
}

.hero-buttons a.sub-btn {
  color: #6ab5f1;
  background-color: transparent;
  width: 100%;
  border-width: 1px;
  border-color: #6ab5f1;
  font-weight: normal;
  border-radius: 6px;
  font-size: 16px;
}

.diver h5 {
  font-size: 2.1rem;
  font-weight: bolder;
}

.diver.blue h5 {
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.diver.yellow h5 {
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.diver.green h5 {
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.diverdotblue {
  width: 24px;
  height: 24px;
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%),
    linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  border-radius: 50%;
}

.hero-notes h1 {
  font-size: 6vw;
  margin-bottom: 0.2rem;
  text-align: left;
  line-height: 1.4;
}

.hero-para p {
  font-size: 1.5vw;
  line-height: 1.5;
}

.hero-notes h1 small {
  font-size: 3vw;
  font-weight: normal;
}

@media only screen and (max-width: 600px) {
  .hero-notes h1 {
    font-size: 10vw;
  }

  .hero-notes h1 small {
    font-size: 4vw;
  }

  .hero-para p {
    font-size: 3.5vw;
  }

  .footer-logo {
    width: 100vw;
  }

  .nav-link button {
    width: 100%;
  }
}

.hero-notes span {
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.develop {
  margin-top: 5em !important;
  margin-bottom: 0 !important;
}

.developerbox {
  margin: -1px;
  padding: 30px;
}

.image-developer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section h4 {
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 10px;
}

.section.row h2 {
  text-align: center;
  margin-bottom: 30px;
}

.process-box {
  margin: -1px;
  padding: 30px;
}

.hor-line {
  background-color: #323232;
  padding: 0;
  width: 1px;
  display: flex;
  align-items: center;
}

.dot {
  position: absolute;
  margin-top: 64px;
  margin-left: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
}

.purpose {
  text-align: center;
  background: #181818;
  padding: 5em 1em;
}

.purpose h2 {
  font-weight: bolder;
  font-size: 1.8em;
  line-height: 1.5;
}

.purpose h3 {
  font-weight: lighter;
  color: #a8a8a8;
}

.purpose span {
  font-size: 1.1em;
}

.stats-glow {
  background: linear-gradient(
    270deg,
    rgba(97, 106, 220, 0.6) -2.84%,
    rgba(107, 193, 245, 0.6) 100%
  );
  filter: blur(30px);
  transform: rotate(0deg);
  width: 50px;
  height: 50px;
  position: relative;
  top: -150px;
  margin: auto;
  margin-top: 30px;
}

.logo-box {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-content: flex-start;
}

.logo-box .links li {
  display: inline-block;
  font-size: 1.6em;
  margin: 5px;
}

.logo-box small {
  color: #ababab;
}

.logo-type {
  font-size: 1.7em;
  margin-top: 10px;
}

.sdks {
  text-align: center;
  padding-top: 20px;
}

.footer-logo {
  width: 30vw;
}

.sdks .item-note {
  text-align: left;
}

.sdks .item-note h2 {
  text-align: left;
}

.sdks h3 {
  font-size: 1.3em;
}

.sdks p {
  color: #bebebe;
}

.sdks .sdk-item {
  padding: 2em 1em;
  border-right-width: 1px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(
      linear,
      0 100%,
      0 0,
      from(#999),
      to(rgba(0, 0, 0, 0))
    )
    1 100%;
  -webkit-border-image: -webkit-linear-gradient(
      bottom,
      rgba(0, 0, 0, 0),
      #999,
      rgba(0, 0, 0, 0)
    )
    1 100%;
  -moz-border-image: -moz-linear-gradient(
      bottom,
      rgba(0, 0, 0, 0),
      #999,
      rgba(0, 0, 0, 0)
    )
    1 100%;
  -o-border-image: -o-linear-gradient(
      bottom,
      rgba(0, 0, 0, 0),
      #999,
      rgba(0, 0, 0, 0)
    )
    1 100%;
  border-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      #999,
      rgba(0, 0, 0, 0)
    )
    1 100%;
}

.sdks .sdk-item:not(:last-child) {
  border-left-width: 1px;
  border-style: solid;
  transition-duration: 0.3s;
  transition-property: box-shadow;
}

.sdks .sdk-item:hover {
  box-shadow: 0 0 15px #666;
}

.sdks {
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: background-color 0.2s;
}

.sdks img {
  height: 80px;
  margin-bottom: 1.5em;
}

.sdks .card-body {
  padding: 1.5em;
}

.price-card h5,
.price-card h2,
.price-card p {
  text-align: center;
}

.price-card {
  gap: 56px;
  background: #171717;
  border-radius: 16px;
  box-shadow: 0px 0px 1px 2px rgba(94, 188, 244, 0.8);
  height: 100%;
  padding-bottom: 50px;
}

.price-card.active {
  border-color: white;
  border-width: 2px;
  background-color: #242424;
}

.price-card h5 {
  font-weight: normal;
}
.price-card h2 {
  font-size: 32px;
}
.price-card span {
  color: #808080;
  font-size: 12px;
}

.price-card button {
  box-shadow: 0px 0px 6px rgba(94, 188, 244, 0.8);
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 27px;
  /* identical to box height, or 159% */
  text-align: center;
  letter-spacing: 0.005em;
  padding: 8px 32px;
  gap: 8px;
  background-color: #1c1c1c;
  color: #ffffff;
}

.price-card .btn-box {
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 24px;
}

.price-card .activebutton {
  padding: 8px 32px;
  gap: 8px;

  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
  box-shadow: 0px 0px 6px rgba(94, 188, 244, 0.8);
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 27px;
  /* identical to box height, or 159% */
  text-align: center;
  letter-spacing: 0.005em;
}

.price-card ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 16px;
}

.price-card li {
  list-style-type: none;
  font-size: 1.2em;
}

.price-item {
  margin-top: 3em;
  padding: 20px;
}

.price-item:not(:last-child) {
  border-right: 1px solid #424242;
}

.price-table h2 {
  text-align: center;
}

.price-table-inner th {
  border-bottom: 1px solid;
  padding: 8px 32px;
}

.price-table-inner td {
  border-bottom: 1px solid;
  padding: 32px;
  font-size: 20px;
}

.price-table-inner th {
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 20px;
}

.price-item tr {
  color: #ffffff;
}

.FAQ h2 {
  text-align: center;
}

.faqs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: 100%;
  height: 418px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  width: 100%;
  border-bottom: 2px solid;
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

/*......End Price......*/

.features-table h3.sub {
  font-weight: lighter;
  font-size: 1.5em;
}

.colorful-text {
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.features-table h3:not(.sub) {
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

  font-weight: bolder;
  font-size: 2.5em;
}

.features-table-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 600px) {
  .features-table-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.features-table-item {
  border: 1px solid #323232;
  padding: 20px;
}

.features-table-item .inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.features-table-item h5 {
  font-size: 1.2em;
  font-weight: lighter;
  color: #ababab;
  margin-bottom: 0;
}

.features-table-item i {
  color: #ababab;
  font-size: 1.7em;
  margin-right: 10px;
}

.features-table-item p {
  color: white;
  font-weight: normal;
  font-size: 1.4em;
  line-height: 1.4;
  text-align: left;
}
/*.......Contact Us......*/

.contactus h4,
.contactus p {
  text-align: center;
}

.submitcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 96px !important;
  background: linear-gradient(270deg, #616adc -2.84%, #6bc1f5 100%) !important;
  box-shadow: 0px 0px 6px rgba(94, 188, 244, 0.8);
  border-radius: 4px;
  width: 260px !important;
  height: 50px !important;
  color: #ffffff !important;
  font-size: 20px;
}
