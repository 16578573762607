html,
body,
#root,
.App {
  font-size: 14px;
  height: 100%;
  background-color: #181818;
}

.App{
  display: flex;
  flex-direction: column;
}

body:not(i):not(#scripts),
b,
h1,
h2,
h3,
login  email  password  LOGINarrow_forward register_alertforget_alert  brandkeyboard_arrow_leftReturn to Home   h4,
h5,
h6,
button,
input,
textarea,
td,
select,
option,
label,
.filepond--root,
.filepond--file-status-main {
  font-family: 'Open-sans', sans-serif;
  color: white;
}

h1,
h2,
h3,
h4,
.card .card-content .card-title {
  font-weight: 800;
}

h5,
h6,
b {
  font-weight: 700;
}

a{
  text-decoration: none !important;
  color: #fafafa;
}

a i{
  margin-right: 5px;
}

a:hover {
  color: white;
}

th {
  color: #fafafa;
}

.brand-logo{
  margin-right: 2em;
}


.no-game span {
  font-size: 18px;
  line-height: 1.7;
  color: #FAFAFA;
  font-weight: lighter;
}

.no-game{
  background-image: url("../../public/images/no-game-bg.svg");
  background-repeat: no-repeat;
  background-size: auto;
  height: 100%;
}

th button.btn-link{
  color: #fafafa !important;
  padding-left: 0;
  font-weight: bold;
  text-decoration: none;
}

.navbar{
  background-color: black;
  padding: 28px 0;
}

.navbar #user-dropdown {
  background-color: #262626;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.navbar #user-dropdown #username{
  color: white;
}

.navbar #user-dropdown.dropdown-toggle::after {
  margin: 5px 5px 5px 15px;
}

.navbar #game-dropdown{
  background-color: #262626;
  border-radius: 30px;
  display: flex;
  align-items: center;
  max-width: 210px;
  min-width: 140px;
  justify-content: space-between;
  padding: 10px 24px;
  margin-left: 20px;
}

.sec-badge{
  border-radius: 3px;
  padding: 3px 10px;
  background-color: #212121;
  margin-left: 10px;
}

.navbar #game-dropdown.dropdown-toggle::after {
  margin: 5px 5px 5px 15px;
}

.navbar .create-game-btn {
  background-color: #262626;
  border-radius: 30px;
  border: 0;
  display: flex;
  align-items: center;
  padding: 10px 24px;
}

.navbar .create-game-btn i {
  margin-right: 5px;
}

.navbar #user-dropdown .avatar {
  margin-right: 7px;
  border-radius: 50%;
}

.nav-link{
  padding: 7px 10px ;
  color: #999999;
}

.nav-link.active{
  color: #FFF;
  font-weight: bold;
}

.nav-link:hover{
  color: #FAFAFA!important;
}

.nav-link i{
  padding-right: 10px;
}

.navbar-brand span {
  margin-left: 10px;
  font-size: 1em;
  color: white;
}

.copy-btn{
  margin-left: 10px;
}

.copy-btn :hover{
  text-shadow: 0 0 20px #FAFAFA;
  /*zoom: 1.2;*/
}

.home-nav .btn.add-service{
  padding: 7px 20px;
  color: white !important;
  border: 0;
  border-radius: 10px;
  background: -webkit-linear-gradient(45deg, #5fc9f8, #5856d6);
}

.home-nav .add-service:hover{
  border: 0;
  box-shadow:  0 0 3px #eeeeee;
  font-weight: bold;
}

.home-nav{
  background-color: black;
}

.home-nav .nav-tabs{
  border-bottom: 0;
}

.home-nav .nav-tabs .nav-item .nav-link{
  margin-right: 10px;
  color: #999;
}

.modal-content{
  background-color: #111111;
  border: 1px solid #424242;
}

.home-nav .btn{
  border-radius: 10px 10px 0 0;
}

.home-nav .nav-tabs .nav-link{
  border-radius: 10px;
}

.home-nav .nav-tabs .nav-link:hover{
  background-color: #424242;
  border: 1px solid transparent;
  color: white;
  border-radius: 10px;
}

.home-nav .nav-tabs .nav-link.active{
  background-color: #111;
  border: 0;
  color: white;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border-bottom: 2px solid white;
}

.table-item a{
  color: #f5f5f5;
}

.select-wrapper .caret {
  left: 0;
  right: auto;
}

.emp {
  padding: 25px !important;
  border: 3px dashed #eee;
}

.emp i {
  font-size: 3em;
}

body {
  /*display: flex;*/
  /*display: -webkit-flex;*/
  /*flex-direction: column;*/
  /*-webkit-flex-direction: column;*/
  height: 100%;
}

i {
  margin: 1px;
  vertical-align: middle;
}

.section-hero{
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-transparent {
  color: #424242;
  padding: 3px 10px;
  border-radius: 30px;
  margin: 1px 5px;
}

.item {
  color: #fff;
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 30px;
  background: #42a5f5;
}

.item-red {
  color: #eee;
  border: 1px solid #eee;
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 30px;
  background: transparent;
}

.item-gray {
  color: #fff;
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 30px;
  background-color: #b5b5be;
}

.item-yellow {
  color: #fff;
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 30px;
  background: #ffb300;
}

.item-green {
  color: #fff;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 30px;
  background: #7cb342;
}

.item-primery {
  color: #fff;
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 30px;
  background: #1976d2;
}

.isomor {
  transition-duration: 0.3s;
  transition-property: box-shadow;
}

.isomor:hover {
  box-shadow: 0 0 10px #bebebe;
}

.navbar-brand {
  display: flex !important;
  align-items: center;
}

.navbar-brand img {
  height: 32px;
}

.storage-bar ul li a {
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 25px;
}

.storage-bar ul li a:hover {
  box-shadow: 0 0 10px #eee;
  background-color: #fff;
  transition: box-shadow 0.3s;
  transition: background-color 0.3s;
}

.storage-bar ul li {
  display: inline-block;
  margin: 5px 10px;
}

.storage-bar a i {
  margin: 0 5px !important;
}

#storage-cap-bar {
  padding: 7px;
  padding-left: 310px;
}

.section_right {
  padding-right: 300px;
}

@media only screen and (max-width: 992px) {
  html {
    font-size: 12px;
  }

  #storage-bar {
    padding-left: 15px;
  }

  .section_right {
    padding-right: 0;
  }

  .storage-bar {
    padding-left: 0;
  }
}

table thead {
  background-color: #222;
}

table th:first-child{
  border-radius: 50px 0 0 50px;
}

table th:last-child{
  border-radius: 0 50px 50px 0;
}

table thead button i {
  margin-right: 5px;
}

.table>thead{
  vertical-align: middle;
}

.table>:not(caption)>*>*{
  border: 0;
}

table tr:nth-child(even){
  background-color: #121212;
}

table td {
  vertical-align: middle;
}

.preloader-wrapper {
  margin: 30px;
}

.circle-clipper {
  direction: ltr !important;
}

.dot {
  width: 30px;
  height: 30px;
  padding: 7px 0 0 0;
  color: #eee;
  font-weight: bold;
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
}

.file-item:hover {
  box-shadow: 0 0 15px #eee;
}

.filepond--root {
  min-height: 30em;
  /* margin-bottom: 0!important; */
}

.filepond--drop-label {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.filepond--drop-label label {
  color: #000;
}

.filepond--root .filepond--list-scroller {
  margin-top: 11em !important;
}

.labels li{
  margin: 5px 15px 5px 0;
}

.spinner-border{
  margin: 0 5px;
}

.modal-open .container-fluid, .modal-open  .container {
  -webkit-filter: blur(3px) grayscale(30%);
}

.card{
  background-color: #000;
  border: 1px solid #525252;
}

.card.selectable:hover{
  border: 1px solid #999999;
  box-shadow: 0 0 15px #666666;
  transition-duration: 0.3s;
}

.card-footer{
  text-align: right;
  background-color: #212121;
  border-top: 1px solid #666666;
}

.card-header{
  background-color: #212121;
  border-bottom: 1px solid #666666;
}

.modal-header{
  text-align: right;
  background-color: #212121;
  border-bottom: 1px solid #666666;
}

.modal-header .btn-close{
  color: white;
}

.modal-footer{
  text-align: right;
  background-color: #212121;
  border-top: 1px solid #666666;
}

.btn-outline-light{
  border: 1px solid #666666;
  color: #999999;
  background-color: #111111;
}

.btn-outline-info{
  border: 1px solid #3dd5f3;
  color: #3dd5f3;
  background-color: #111111;
}

.btn-outline-danger{
  border: 1px solid #e53935;
  color: #bb2d3b;
  background-color: #111111;
}
.btn-outline-secondary{
  border: 1px solid #666666;
  color: #666666;
  background-color: #111111;
}

.empty-banner{
  text-align: center;
  padding: 30px;
  margin-top: 30px;
  background-color: #212121;
  border: 5px dashed #424242;
  border-radius: 15px;
}

.empty-banner img{
  margin-bottom: 20px;
}

.empty-banner i{
  font-size: 3em;
  margin-bottom: 20px;
}

.empty-banner.no-border{
  border-width: 0;
}

.url-chart .progress{
  background-color: transparent;
}

.url-chart .progress .label{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 1.4em;
  position: absolute;
  right: 10px;
  left: 10px;
}

.leaderboard-action{
  border-bottom: 2px solid #666;
  margin-bottom: 7px;
}

.leaderboard-item{
  border-top: 1px solid #444;
  padding-bottom: 10px;
  cursor: pointer;
}

.leaderboard-item:hover{
  box-shadow: 0 0 15px #525252;
  transition-duration: 0.3s;
}

.sticker{
  margin-top: 20px;
  margin-bottom:20px;
  background-color: #424242;
  border-radius: 15px;
  padding: 20px
}

.sticker span{
  border-left: 5px solid green;
  padding-left: 10px;
  border-radius:3px;
}

.sticker h4{
  margin-top: 15px;
}

select option{
  background-color: #424242;

}

.add-service .dropdown-toggle::after{
  display: none;
}

.user-input .dropdown-menu.show {
  right: 0 !important;
}

.block-text-input{
  border-radius: 7px;
  padding: 7px;
  font-size: 1.1em;
  background-color: #323232;
  color: white;
  border: 0;
}

.pagination{
  justify-content: center;
}

.card-body.have-padding {
  padding:2em;
}

.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
  flex-direction: column;
}

.error-page i{
  margin: 2rem;
  font-size: 6em;
  color: #f5f5f5;
}

.error-page small{
  font-size: 1.3em;
  font-weight: lighter;
  color: #ababab;
}


.service-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-item img {
  width: 4em;
  height: 4em;
  background-color: #eeeeee;
  border-radius: 7px;
}

.service-categories a{
  border-radius: 7px;
  margin-bottom: 5px;
}

.service-categories a.active{
  background-color: #3367d9!important;
}

.service-categories a:hover{
  background-color: #626262;
}

.game-logo{
  width: 100px;
  height: 100px;
  padding: 2px;
  background-color: #666;
  margin-right: 15px;
  border-radius: 50%;
}

